<template>
    <!--
    Страница ввода кода подтверджения
    -->
    <div class="wrapper">
        <div class="form-part">
            <div class="logo-part" v-if="$vssWidth > 668 && $vssHeight > 415">
                <img class="logo" alt="logo" src="../assets/logo-lg.png">
            </div>

            <div class="form-wrapper">
                <div class="code-wrapper">
                    <label for="code">Введите код подтверждения из {{ code_mode | code_mode_name }}</label>
                    <input id="code" name="code" type="password" v-model="code">
                </div>

                <input type="submit" value="Войти в систему" @click="onLogin" :disabled="!valid">

                <div v-if="isCodeValid" class="code-validity">Код действителен {{ formattedTimeLeft }}</div>
                <div v-else-if="code_ttl" class="code-validity-expired">Код устарел</div>

                <div v-if="needCountdown" class="next-attempt disabled">Отправить код повторно через {{ timeToNext }} секунд</div>
                <a v-else class="next-attempt" href="#" @click="resendCode">Отправить код повторно</a>

            </div>
        </div>
        <div class="footer_links_wrapper">
            <router-link :to="{name: 'PrivacyPolicy'}" class="footer_link">
                Политика конфиденциальности
            </router-link>
            <router-link :to="{name: 'AboutCookie'}" class="footer_link">
                О cookie
            </router-link>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import VueScreenSize from "vue-screen-size";

export default {
    props: {
      timeLeft: {
        type: Number,
        required: true
      },
    },
    mixins: [VueScreenSize.VueScreenSizeMixin],
    name: 'Home',
    components: {},

    data: () => ({
        code: null,
        timeToNext: null,
        countdownTimeout: null,
        timePassed: 0,
        timerInterval: null,
        now: null,
    }),

    computed: {
        ...mapGetters("login", ["code_ttl", "code_mode", "next_attempt", "email"]),

        valid() {
            return !!this.code
        },

        needCountdown() {
            return this.next_attempt > this.now;
        },

        isCodeValid() {
            return this.timePassed < this.code_ttl;
        },

      formattedTimeLeft() {
        const timeLeft = this.timeLeft
        const minutes = Math.floor(timeLeft / 60)
        let seconds = timeLeft % 60
        if (seconds < 10) {
          seconds = `0${seconds}`
        }
        return `${minutes}:${seconds}`
      },

      timeLeft() {
        return this.code_ttl - this.timePassed
      },

    },

    created() {
        this.startTimer();
        if (this.needCountdown) {
            if (this.countdownTimeout)
                clearTimeout(this.countdownTimeout);
            const self = this
            this.countdownTimeout = setInterval(() => {
                self.now = Math.floor(Date.now() / 1000)
                self.timeToNext = self.next_attempt - self.now;
            }, 400);
        } else {
            if (this.countdownTimeout) {
                clearTimeout(this.countdownTimeout);
                this.countdownTimeout = null
            }
        }
    },

    destroyed() {
        if (this.countdownTimeout)
            clearTimeout(this.countdownTimeout);
    },

    methods: {
        // Accessing actions
        ...mapActions("login", ["get_code", "check_code"]),
        ...mapActions("user", ["get_me"]),

      onLogin() {
            const code = this.code;
            this.code = null;
            this.check_code(code)
                .then(() => this.get_me())
                .then(() => {
                    this.$router.push({name: 'profile'})
                })
        },

      resendCode() {
        const self = this
        this.get_code(this.email).then(() => {
          self.now = Math.floor(Date.now() / 1000)
        })
        this.timePassed = 0;
      },

      startTimer() {
        this.timerInterval = setInterval(() => (this.timePassed++), 1000);
      },

    },
}
</script>

<style scoped lang="scss">

@import "../theme";

.footer_links_wrapper {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-content: space-around;
    justify-content: flex-end;
    align-items: center;

    .footer_link {
        margin: 0 7px;
        color: $dom-light-gray;
        text-decoration: none;
    }

    .footer_link:hover {
        text-decoration: underline;
    }
}

.wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: auto;
    flex-direction: column;

    background-image: url("../assets/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo-part {
    align-items: center;
    margin-top: -100px;

    img {
        display: block;
        margin: 0 auto;
        padding: 0 0 20px 0;
    }
}

.form-part {
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: center;

    .form-wrapper {
        margin: 0 auto;
        height: 300px;
        max-width: 480px;
        box-sizing: border-box;
        background-color: $dom-blue;
        border-radius: 16px;
        box-shadow: #00000030 1px 4px 10px 3px;

        padding: 40px;

        input {
            outline: none;
            border: none;
            height: 50px;
            box-sizing: border-box;
            padding: 15px 15px;

            border-radius: 5px;

            font-family: Arial, serif;
            font-size: 12pt;

            background-color: $dom-light-blue;
            color: white;

            &::-moz-placeholder {
                color: white;
            }

            &::-webkit-input-placeholder {
                color: white;
            }

            margin: 10px 0;

        }

        label {
            color: white;
        }

        input[type=password] {
            display: inline-block;
            background-color: $dom-light-blue;
            width: 110px;
            margin-left: 40px;

        }

        input[type=submit] {
            width: 100%;
            background-color: $dom-green;

            box-shadow: $dom-dark-blue 0 2px 0 0;
            cursor: pointer;

            &:active {
                position: relative;
                top: 2px;
                box-shadow: $dom-dark-blue 0 0 0 0;
            }
        }

        .code-validity {
            color: white;
            margin: 20px 0;
        }

        .code-validity-expired {
          color: darkred;
          margin: 20px 0;
          font-weight: bold;
        }

        .next-attempt {
            color: white;
            margin: 20px 0;

            &.disabled {
                color: $dom-light-blue
            }
        }

    }
}

</style>
